import { useFlags } from 'launchdarkly-react-client-sdk';

export interface UseAppWhitelistFlag {
  apps: string[];
  realms: string[];
}

export type LaunchDarklyFlags = {
  disablePlacementStatusButton: boolean;
  enableClarity: boolean;
  enableStartup: boolean;
  ignorePendingInvitationRedirect: boolean;
  showBrokerReports: boolean;
  showBuyNowUrl: boolean;
  showCommissionsInNav: boolean;
  showCompensationInNav: boolean;
  showMyTeamGenealogyCard: boolean;
  showMyTeamInNav: boolean;
  showMyTeamLegacyPlacementCard: boolean;
  showMyTeamPlacementCard: boolean;
  showNewObr: boolean;
  showNewTaxForms: boolean;
  showSitePreferencesOfferingCards: boolean;
  startupMaximumEffectiveDateAge: number;
  useAppWhitelist: UseAppWhitelistFlag;
  useNewEngageTokenEndpoint: boolean;
  useNewMyStatisticsUrl: boolean;
  useNewProspectTokenEndpoint: boolean;
};

export const useLaunchDarkly = () => useFlags<LaunchDarklyFlags>();
