import React, { FC, Fragment, useEffect } from 'react';
import { Footer, Layout, Step, Grid, GridRow, GridCol, Loader, useLoader } from '@legalshield/adonis-ux-framework';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';
import { clarity } from 'react-microsoft-clarity';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { CompleteYourAccount } from './components/v1/completeYourAccount/CompleteYourAccount';
import { ForgotEmailUsername } from './components/v1/forgotEmailUsername/ForgotEmailUsername';
import { ForgotPassword } from './components/v1/forgotPassword/ForgotPassword';
import { Login } from './components/v1/login/Login';
import { NewFlow } from './components/v2/newFlow/NewFlow';
import { PageNotFound } from './components/pageNotFound/PageNotFound';
import { ResetPassword } from './components/v1/resetPassword/ResetPassword';
import { SignUp } from './components/v1/signUp/SignUp';
import { UrlService } from './services';
import { VerifyIdentity } from './components/v1/verifyIdentity/VerifyIdentity';
import { currentEnv } from './config/envConfig';
import { useLaunchDarkly } from './hooks/useLaunchDarkly';

import './App.css';

const App: FC = () => {
  const app = UrlService.getApp();
  const realm = UrlService.getRealm();
  let location = useLocation();
  const { enableClarity, useAppWhitelist } = useLaunchDarkly();

  const isFlagReady = useAppWhitelist !== undefined;

  useEffect(() => {
    if (enableClarity && (currentEnv === 'uat' || currentEnv === 'sandbox' || currentEnv === 'production')) {
      clarity.init('n7u07nzu3h');
    }
  }, [enableClarity]);

  const isCheckout = app && ['checkoutv3', 'checkout', 'finalcheckout'].includes(app);

  let stepNames = null;
  const showStepNames = realm === Realm.User && isCheckout;

  if (showStepNames) {
    stepNames = [string_table.PURCHASE_STEP1_SIGN_IN, string_table.PURCHASE_STEP2, string_table.PURCHASE_STEP3];
    if (location.pathname === '/sign-up') {
      stepNames = [string_table.PURCHASE_STEP1__SIGN_UP, string_table.PURCHASE_STEP2, string_table.PURCHASE_STEP3];
    }
  }

  const Steps = ({ stepNames }: { stepNames?: string[] | null }) => {
    if (!stepNames) return <Fragment />;

    return (
      <GridRow variant="pillar">
        <GridCol>
          <Step size="auto" stepNames={stepNames} completed={[]} clickable={[]} activeIndex={0} onClick={() => {}} />
        </GridCol>
      </GridRow>
    );
  };

  // Initialize the loader and show it until the flag is ready, ONLY when the URL is / or /login
  const loader = useLoader();

  const isRouteDynamic = ['/', '/login', 'sign-up'].includes(location.pathname);
  if (!isFlagReady && isRouteDynamic) {
    return (
      <Layout variant="scroll">
        <Grid>
          <Loader loaderState={{ ...loader.loaderState, loading: true }} />
        </Grid>
      </Layout>
    );
  }

  const appsToUseV2 = useAppWhitelist?.apps || [];
  const realmsToUseV2 = useAppWhitelist?.realms || [];
  const isMobile = new URLSearchParams(window.location.search).get('intent') === 'mobile';
  const shouldAppUseNewFlow = (
    app: string | null,
    realm: string | null,
    appsToUseV2: string[],
    realmsToUseV2: string[],
    isMobile: boolean
  ) => isMobile || (app && appsToUseV2.includes(app)) || (realm && realmsToUseV2.includes(realm));

  const useNewFlow = shouldAppUseNewFlow(app, realm, appsToUseV2, realmsToUseV2, isMobile);

  return (
    <Layout variant="scroll">
      <Grid>
        <Steps stepNames={stepNames} />
        <Routes>
          {realm !== Realm.Internal ? (
            <>
              {/* Dynamic Routes */}
              {useNewFlow ? <Route path="/" element={<NewFlow />} /> : <Route path="/" element={<Login />} />}
              {useNewFlow ? <Route path="/login" element={<NewFlow />} /> : <Route path="/login" element={<Login />} />}
              {useNewFlow ? (
                <Route path="/sign-up" element={<NewFlow flow={'signUp'} />} />
              ) : (
                <Route path="/sign-up" element={<SignUp />} />
              )}

              {/* Routes pointing to V1 */}
              <Route path="/groups" element={<Login />} />
              <Route path="/internal" element={<Login />} />
              <Route path="/providers" element={<Login />} />
              <Route path="/complete-your-account/update" element={<CompleteYourAccount />} />
              <Route path="/forgot-email-username" element={<ForgotEmailUsername />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/update" element={<ResetPassword />} />
              <Route path="/verify-identity" element={<VerifyIdentity />} />

              {/* Routes pointing to V2 */}
              <Route path="/new-forgot-password" element={<NewFlow flow={'forgotPassword'} />} />
              <Route path="/new-login" element={<NewFlow flow={'login'} />} />
              <Route path="/new-root" element={<NewFlow />} />
              <Route path="/new-sign-up" element={<NewFlow flow={'signUp'} />} />
              <Route path="/new-verify-identity" element={<NewFlow flow={'setPassword'} />} />
            </>
          ) : null}
          {/* 404 Routes */}
          <Route path="page-not-found" element={<PageNotFound />} />
          <Route path="/*" element={<Navigate to="/page-not-found" replace />} />
        </Routes>
      </Grid>

      <>{!isMobile && <Footer align="center" htmlContent={footerHtml} />}</>
    </Layout>
  );
};

export default withLDProvider({
  clientSideID: LDClientID,
  options: {
    bootstrap: 'localStorage',
  },
  context: {
    kind: 'organization',
    key: 'PPLSI',
    name: 'PPLSI',
  },
})(App);
