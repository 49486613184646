import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

const PROTOCOL = 'https://';

const getLoggedInRedirect = ({
  appOverride,
  pathOverride,
  friendlyId,
  accountType,
}: {
  appOverride?: string | null;
  pathOverride?: string | null;
  friendlyId?: string | null;
  accountType?: string | null;
}) => {
  const inputParams = new URLSearchParams(window.location.search);
  const port = getPort(inputParams);
  let app = getAppFromParams(inputParams, appOverride);

  if (!app) app = getAppForRealm();

  let path = getPathFromParams(inputParams, pathOverride);

  const outputParams = new URLSearchParams();
  /**
   * This parameter indicates that the user has just been signed in
   * and they are being directed back to the application. `1` mean success.
   */
  outputParams.append('login_redirect', '1');

  inputParams.forEach((value, key) => {
    if (value !== null && value !== 'null') outputParams.append(key, value);
  });

  if (app === 'api.')
    // LS API Specific Logic
    addLSAPIParams(friendlyId, accountType, outputParams);

  return PROTOCOL + app + getDomain() + port + path + getParamString(outputParams);
};

const getDomain = () => {
  const { hostname } = window.location;
  // counts back from the last '.' (the one before the Top-Level Domain) to the second to last '.' (Will not work for .co.uk)
  return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
};

const getApp = () => {
  const params = new URLSearchParams(window.location.search);

  return params.get('app');
};

const getProductFamily = (): string | null => {
  const params = new URLSearchParams(window.location.search);

  return params.get('product_family');
};

const decodePathParam = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('path') ?? '';
};

const isIDShield = (): boolean => {
  return getApp() == null ? false : getApp()!.startsWith('ids');
};

const isLsApi = (): boolean => {
  return getApp() == null ? false : getApp()!.startsWith('api');
};

const isAccess = (): boolean => {
  /**
   * This function checks if the current URL is an Access URL
   * Example URL: https://login.legalshield.com/login?app=accounts&path=access
   */
  return getApp() === 'accounts' && decodePathParam() === 'access';
};

const isActivate = (): boolean => {
  /**
   * This function checks if the current URL is an Activate URL
   * Example URL: https://login.legalshield.com/login?app=accounts&path=activate
   */
  return getApp() === 'accounts' && decodePathParam() === 'activate';
};

const getRealm = () => {
  let realm: Realm = Realm.User;
  if (
    window.location.hostname.endsWith('legalshieldinternal.com') ||
    window.location.hostname.endsWith('pplsipeople.com')
  ) {
    realm = Realm.Internal;
  } else if (
    window.location.hostname.endsWith('legalshieldatwork.com') ||
    window.location.hostname.endsWith('shieldatwork.com')
  ) {
    realm = Realm.GroupAdmin;
  } else if (
    window.location.hostname.endsWith('legalshieldproviders.com') ||
    window.location.hostname.endsWith('pplsipartners.com') ||
    window.location.hostname.endsWith('shieldproviders.com')
  ) {
    realm = Realm.Partner;
  }

  return realm;
};

function getAppForRealm(): string {
  const realm = getRealm();
  if (realm === Realm.Internal) {
    return 'permissions.';
  } else if (realm === Realm.GroupAdmin) {
    return 'groups.';
  } else if (realm === Realm.Partner) {
    return 'partner.';
  } else {
    return 'accounts.';
  }
}

/**
 * Gets the port from the inputParams and removes the port from the inputParams
 * WARNING: This function modifies the inputParams
 * @param inputParams
 * @returns the port from the inputParams
 */
function getPort(inputParams: URLSearchParams): string {
  const port = inputParams.get('port') ? `:${inputParams.get('port')}` : '';
  inputParams.delete('port');
  return port;
}

/**
 * Gets the app from the inputParams and removes the app from the inputParams
 * WARNING: This function modifies the inputParams
 * @param inputParams
 * @returns the port from the inputParams
 */
function getAppFromParams(inputParams: URLSearchParams, appOverride: string | null = null): string {
  const app = appOverride ?? (inputParams.get('app') ? `${inputParams.get('app')}.` : '');
  inputParams.delete('app');

  if (app.includes('accountsv2')) return 'accounts.';
  return app;
}

/**
 * Gets the path from the inputParams and removes the path from the inputParams
 * WARNING: This function modifies the inputParams
 * @param inputParams params from the current URL
 * @param pathOverride optional path to override params
 * @returns the path from the inputParams
 */
function getPathFromParams(inputParams: URLSearchParams, pathOverride: string | null = null): string {
  let path = pathOverride ?? inputParams.get('path') ?? '';
  inputParams.delete('path');
  if (path.includes('?')) {
    const pathParts = path.split('?');
    path = pathParts[0];
    new URLSearchParams(pathParts[1]).forEach((value, key) => {
      if (!inputParams.has(key)) inputParams.append(key, value);
    });
  }

  return path.startsWith('/') ? path : '/' + path;
}

function getParamString(outputParams: URLSearchParams): string {
  return outputParams.toString() ? '?' + outputParams.toString() : '';
}

function addLSAPIParams(
  friendlyId: string | null | undefined,
  accountType: string | null | undefined,
  outputParams: URLSearchParams
) {
  outputParams.append('friendly_id', friendlyId!);
  outputParams.append('account_type', accountType!);
}

export default {
  decodePathParam,
  getLoggedInRedirect,
  getApp,
  getRealm,
  isAccess,
  isActivate,
  isIDShield,
  isLsApi,
  getDomain,
  getProductFamily,
};
